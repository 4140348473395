export class Usuario {
    idCliente:number;
    RazonSocial:string;
    NoEstacion:string;
    Correo:string;
    Telefono:string;
    Contacto:string;
    Contrasenna:string;
    cambioContrasenna:number;
}
