
        <nav class="navbar navbar-expand-md bg-dark navbar-dark">
            <!-- Brand -->
            <a class="navbar-brand" style="color: white;" (click)="Redirige('inicio')"><img src="/assets/icons/icon-72x72.png" alt="" style="height: 5vh;"></a>
        
            <!-- Toggler/collapsibe Button -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span class="navbar-toggler-icon"></span>
            </button>
        
            <!-- Navbar links -->
            <div class="collapse navbar-collapse ml-5" id="collapsibleNavbar">
                <ul class="navbar-nav ml-5" style="color: #DFF2F0;">
                    <li class="nav-item dropdown">
                        <a (click)="Redirige('inicio')" style="color: white;"><i class="fas fa-home"></i>&nbsp;Inicio</a>
                    </li>
                    <li class="nav-item ml-3">
                        <a (click)="Redirige('nuevoticket')" style="color: white;"><i class="fas fa-plus"></i>&nbsp;Nuevo ticket</a>
                    </li>
                    <li class="nav-item ml-3">
                        <a (click)="Redirige('ticketsactivos')" style="color: white;"><i class="far fa-edit"></i>&nbsp;Tickets activos</a>
                    </li>
                    <li class="nav-item ml-3">
                        <a (click)="Redirige('ticketsfinalizados')" style="color: white;"><i class="fas fa-check"></i>&nbsp;Tickets finalizados</a>
                    </li>
                    <li class="nav-item ml-3">
                        <a (click)="openAcercaDe(mymodal5)" style="color: white;"><i class="fas fa-info-circle"></i>&nbsp;Acerca de</a>
                    </li>
                    <li class="nav-item ml-3" >
                        <a (click)="LogOut()" style="color: white;"><i class="fas fa-sign-out-alt" ></i>&nbsp;Salir</a>
                    </li>
                    
                </ul>
            </div>
        </nav>
        <div class="container-fluid pt-3" id="contenido">
            <h3>Tickets finalizados</h3>
            <div class="row mt-5">
                <div class="col-sm-12" style="height: 90vh;overflow-y: auto;">
                    <table class="table table-hover">
                        <thead id="cabecera">
                            <tr>
                                <th scope="col"><i class="far fa-file-alt"></i>&nbsp;Folio</th>
                                <th scope="col"><i class="far fa-calendar-alt"></i>&nbsp;Fecha de recepción</th>
                                <th scope="col"><i class="fas fa-calendar-check"></i>&nbsp;Fecha de finalización</th>
                                <th scope="col"><i class="fas fa-exclamation-triangle"></i>&nbsp;Prioridad</th>
                                <th scope="col"><i class="fas fa-puzzle-piece"></i>&nbsp;Módulo, servicio o reporte</th>
                                <th scope="col"><i class="far fa-file"></i>&nbsp;Resumen</th>
                                <th scope="col"><i class="fas fa-list"></i>&nbsp;Detalles</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let ticket of tickets">
                                <th scope="row">{{ticket.folio}}</th>
                                <td>{{ticket.fecha}}</td>
                                <td>{{ticket.fechaCierre}}</td>
                                <td>{{ticket.prioridad}}</td>
                                <td>{{ticket.modulo}}</td>
                                <td>{{ticket.resumen}}</td>
                                <td><span id="{{ticket.folio}}" class="detalle" (click)="open(mymodal,$event.target)"><i class="far fa-file-alt"></i></span></td>
                            </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>

<!-- modal -->
<ng-template #mymodal let-modal>
    <div class="modal-header">
        <h3 class="modal-title" id="modal-basic-title">Reporte</h3>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">×</span>
      </button>
    </div>
    <div class="modal-body">
        <div class="row">
            <div class="col-sm-12">
                <table class="table table-hover">
                    <thead id="cabecera">
                        <tr>
                            <th scope="col"><i class="far fa-file-alt"></i>&nbsp;Folio</th>
                            <th scope="col"><i class="fas fa-layer-group"></i>&nbsp;Nivel</th>
                            <th scope="col"><i class="far fa-calendar-alt"></i>&nbsp;Fecha</th>
                            <th scope="col"><i class="far fa-sticky-note"></i>&nbsp;Notas</th>
                            <th scope="col"><i class="fas fa-info-circle"></i>&nbsp;Estado</th>

                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let servicio of servicios">
                            <th scope="row">{{servicio.Folio}}</th>
                            <td>{{servicio.Nivel}}</td>
                            <td>{{servicio.Fecha}}</td>
                            <td>{{servicio.Notas}}</td>
                            <td>{{servicio.Estado}}</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-block" (click)="modal.close('Save click')">Ok</button>
    </div>
</ng-template>

<!-- modal -->
<ng-template #mymodal5 let-modal5>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title"></h5>
        <button type="button" class="close" aria-label="Close" (click)="modal5.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>

<div class="modal-body">
    <div class="row">
        <div class="col-sm-12" id="logocontainer">
            <img src="assets/descarga.png" id="logo" alt="">
        </div>
        <div class="col-sm-12">
            <h4>SACK - Servicio de atención a clientes V.4.1.0.1</h4>
        </div>
        <div class="col-sm-12">
            <h6><b>Agua Soluciones</b></h6>
        </div>
        <div class="col-sm-12">
            <h6>Avenida Ayuntamiento No. 413 Int. 301 Colonia Los Cedros</h6>
        </div>
        <div class="col-sm-12">
            <h6>Aguascalientes, Ags., Mexico</h6>
        </div>

    </div>
</div>
<div class="modal-footer">
    <div class="col-sm-12 " style="text-align: center;">
        <h6>Copyright© SACK 2021.</h6>
    </div>
</div>
</ng-template>
