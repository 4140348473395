<ng-template #editProfileModal let-modal>
    <div class="modal-header">
        <h5 class="modal-title" id="editProfileLabel">Edit Profile</h5>
        <button type="button" class="close" (click)="modal.dismiss()" aria-label="Close">
   <span aria-hidden="true">&times;</span>
  </button>
    </div>

    <div class="modal-body">
        <form [formGroup]="editProfileForm" (ngSubmit)="onSubmit()">
            <div class="form-group row">
                <label for="firstname" class="col-sm-4 col-form-label">First Name</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="firstname" id="firstname">
                </div>
            </div>
            <div class="form-group row">
                <label for="lastname" class="col-sm-4 col-form-label">Last Name</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="lastname" id="lastname">
                </div>
            </div>
            <div class="form-group row">
                <label for="username" class="col-sm-4 col-form-label">Username</label>
                <div class="col-sm-8">
                    <input type="text" class="form-control" formControlName="username" id="username">
                </div>
            </div>
            <div class="form-group row">
                <label for="email" class="col-sm-4 col-form-label">Email</label>
                <div class="col-sm-8">
                    <input type="email" class="form-control" formControlName="email" id="email">
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="modal.dismiss()">Close</button>
                <button type="submit" class="btn btn-primary">Save changes</button>
            </div>
        </form>
    </div>
</ng-template>