
        <nav class="navbar navbar-expand-md bg-dark navbar-dark">
            <!-- Brand -->
            <a class="navbar-brand" style="color: white;" (click)="Redirige('inicio')"><img src="/assets/icons/icon-72x72.png" alt="" style="height: 5vh;"></a>
        
            <!-- Toggler/collapsibe Button -->
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                <span class="navbar-toggler-icon"></span>
            </button>
        
            <!-- Navbar links -->
            <div class="collapse navbar-collapse ml-5" id="collapsibleNavbar">
                <ul class="navbar-nav ml-5" style="color: #DFF2F0;">
                    <li class="nav-item dropdown">
                        <a (click)="Redirige('inicio')" style="color: white;"><i class="fas fa-home"></i>&nbsp;Inicio</a>
                    </li>
                    <li class="nav-item ml-3">
                        <a (click)="Redirige('nuevoticket')" style="color: white;"><i class="fas fa-plus"></i>&nbsp;Nuevo ticket</a>
                    </li>
                    <li class="nav-item ml-3">
                        <a (click)="Redirige('ticketsactivos')" style="color: white;"><i class="far fa-edit"></i>&nbsp;Tickets activos</a>
                    </li>
                    <li class="nav-item ml-3">
                        <a (click)="Redirige('ticketsfinalizados')" style="color: white;"><i class="fas fa-check"></i>&nbsp;Tickets finalizados</a>
                    </li>
                    <li class="nav-item ml-3">
                        <a (click)="openAcercaDe(mymodal5)" style="color: white;"><i class="fas fa-info-circle"></i>&nbsp;Acerca de</a>
                    </li>
                    <li class="nav-item ml-3" >
                        <a (click)="LogOut()" style="color: white;"><i class="fas fa-sign-out-alt" ></i>&nbsp;Salir</a>
                    </li>
                    
                </ul>
            </div>
        </nav>
        <div class="container-fluid pt-5 pl-5"  id="contenido">
            
            <div class="row">
                <div class="col-sm-1-mt6" style="text-align: center;">
                <!-- <img src="../assets/descarga.png" style="height: 30vh;margin-left: 20vh;margin-top: 25vh;" alt=""> -->
                <h2 class="blue-text" >Soluciones para Organismos Operadores de Agua Potable</h2>
                </div>
<br>
<br>
<br>     
                <div class="card-deck">
                    <div class="card">
                      <img class="card-img-top" src="assets/home01.jpg" alt="Sistema Comercial de Agua">
                      <div class="card-body">
                        <h5 class="card-title">Sistema Comercial de Agua</h5>
                        <p class="card-text">Agua Soluciones ofrece un sistema de información para la administración de organismos operadores de servicios de agua potable, empresas privadas de agua y saneamiento, sistemas de agua potable y alcantarillado.</p>                   
                        <a href="http://www.aguasoluciones.com.mx/es/sistema_comercial_agua.html" class="card-link" style="float: left;color:#148C80;font-size:large;">Ver más</a>  
                    </div>
                    </div>
                    <div class="card">
                      <img class="card-img-top" src="assets/home02.jpg" alt="Sistema Administrativo Modula">
                      <div class="card-body">
                        <h5 class="card-title">Sistema Administrativo Modula</h5>
                        <p class="card-text">Esta solución es superior a los sistemas administrativos del mercado por ser cien por ciento configurable y orientado a las necesidades de los organismos operadores de agua.</p>
                        <a href="http://www.aguasoluciones.com.mx/es/sistema_administrativo.html" class="card-link" style="float: left;color:#148C80;font-size:large;">Ver más</a>  
                    </div>
                    </div>
                    <div class="card">
                      <img class="card-img-top" src="assets/home03.jpg" alt="Sistema de Mantenimiento">
                      <div class="card-body">
                        <h5 class="card-title">Sistema de Mantenimiento</h5>
                        <p class="card-text">Es un sistema de información para controlar el mantenimiento a fuentes de abastecimiento, vehículos, maquinaria y equipo, esto es esencial en la operación diaria de los organismos operadores de agua.</p>
                        <a href="http://www.aguasoluciones.com.mx/es/sistema_mantenimiento_preventivo.html" class="card-link" style="float: left;color:#148C80; font-size:large;">Ver más</a>  
                    </div>
                    </div>
                    <div class="card">
                      <img class="card-img-top" src="assets/home04.jpg" alt="Cajeros Automáticos">
                      <div class="card-body">
                        <h5 class="card-title">Cajeros Automáticos</h5>
                        <p class="card-text">Agua Soluciones es socio de negocios de los principales fabricantes de cajeros automáticos en México, esto nos ha permitido integrar nuestro Sistema Comercial de Agua de una forma rápida, sencilla y segura.</p>
                        <a href="http://www.aguasoluciones.com.mx/es/cajeros_automaticos.html" class="card-link" style="float: left;color:#148C80;font-size:large;">Ver más</a>  
                    </div>
                    </div>
                  </div>
                  <br>
                  <br>
                <div class="card-deck">
                    <div class="card">
                    <img class="card-img-top" src="assets/home05.jpg" alt="Terminales Portátiles">
                    <div class="card-body">
                        <h5 class="card-title">Terminales Portátiles</h5>
                        <p class="card-text">Las terminales portátiles de uso rudo marca Psion son las que tienen mejor relación costo–beneficio del mercado y con tecnología probada en organismos operadores de agua en México.</p>
                        <a href="http://www.aguasoluciones.com.mx/es/terminales_portatiles.html" class="card-link" style="float: left;color:#148C80;font-size:large;">Ver más</a>
                    </div>
                    </div>
                    <div class="card">
                    <img class="card-img-top" src="assets/home06.jpg" alt="Realización de Censos">
                    <div class="card-body">
                        <h5 class="card-title">Realización de Censos</h5>
                        <p class="card-text">Tenemos una amplia experiencia en el levantamiento de censos en campo orientados a los organismos operadores de agua con el principal objetivo de obtener un padrón de usuarios y de tomas real y confiable.</p>
                        <a href="http://www.aguasoluciones.com.mx/es/realizacion_censos.html" class="card-link" style="float: left;color:#148C80;font-size:large;">Ver más</a>
                    </div>
                    </div>
                    <div class="card">
                    <img class="card-img-top" src="assets/home07.jpg" alt="Planes Maestros">
                    <div class="card-body">
                        <h5 class="card-title">Planes Maestros</h5>
                        <p class="card-text">Servicios de consultoría para elaborar los elementos catastrales de redes hidráulicas y generar modelos matemáticos que simulen las condiciones reales de operación del sistema de agua potable de su municipio.</p>
                        <a href="http://www.aguasoluciones.com.mx/es/planes_maestros.html" class="card-link" style="float: left;color:#148C80;font-size:large;">Ver más</a>
                    </div>
                    </div>
                    <div class="card">
                    <img class="card-img-top" src="assets/home08.jpg" alt="Gestión Integral de Recursos">
                    <div class="card-body">
                        <h5 class="card-title">Gestión Integral de Recursos</h5>
                        <p class="card-text">Agua Soluciones cuenta con amplia experiencia en apoyo a los Organismos Operadores de Agua en México, en gestión integral de recursos, en todas las etapas de Planeación, Estudios, Administración y Auditorías.</p>
                        <a href="http://www.aguasoluciones.com.mx/es/gestion_integral_recursos.html" class="card-link" style="float: left;color:#148C80;font-size:large;">Ver más</a>
                    </div>
                    </div>

                    
                </div>
                <br>
                <br>
                <!--<p style="color: gray; text-align: center;" >Copyright© Agua Soluciones 2021.</p>-->
            </div>
            <p style="color: gray; text-align: center;" >Copyright© Agua Soluciones 2021.</p>
        

    </div>
    



<!-- modal -->
<ng-template #mymodal5 let-modal5>
    <div class="modal-header">
        <h5 class="modal-title" id="modal-basic-title"></h5>
        <button type="button" class="close" aria-label="Close" (click)="modal5.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>

<div class="modal-body">
    <div class="row">
        <div class="col-sm-12" id="logocontainer">
            <img src="assets/descarga.png" id="logo" alt="">
        </div>
        <div class="col-sm-12">
            <h4>SACK - Servicio de atención a clientes V.4.1.0.1</h4>
        </div>
        <div class="col-sm-12">
            <h6><b>Agua Soluciones</b></h6>
        </div>
        <div class="col-sm-12">
            <h6>Avenida Ayuntamiento No. 413 Int. 301 Colonia Los Cedros</h6>
        </div>
        <div class="col-sm-12">
            <h6>Aguascalientes, Ags., Mexico</h6>
        </div>

    </div>
</div>
<div class="modal-footer">
    <div class="col-sm-12 " style="text-align: center;">
        <h6>Copyright© SACK 2021.</h6>
    </div>
</div>
</ng-template>